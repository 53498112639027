import React from "react";

export default function TextInput({ type, placeholder, onChange, value, name}) {
  return (
    <input
      className="outline-none border mb-[11px] border-black bg-transparent px-4 py-[10px] text-[13px] leading-[177.4%] font-primary font-normal text-black placeholder-black w-full"
      required
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      name={name}
    />
  );
}
